import React from "react"
import { Link } from "gatsby"

const Header = () => {


  return (
  	<>
  	<span className='basis-full bg-zinc-900 text-yellow-300 text-sm fixed top-0 left-0 right-0 h-8 text-center pt-1 z-20'>The directory for buying beef, bison, fish, and more online.</span>
    <header className={'fixed top-8 left-0 right-0 bg-yellow-300 border-b-2 border-zinc-900 h-20 flex items-center justify-center flex-wrap z-20'}>
    	<nav className={'flex items-center justify-between max-w-6xl mx-auto px-2 w-full'}>
    	<Link to={'/'}>
	    	<svg className='h-6  fill-zinc-900' viewBox="0 0 662 120" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M188.728 108V26.8H213.9L233.968 65.08L254.036 26.8H279.208V108H260.416V52.668L233.736 102.2L207.056 52.436V108H188.728Z"/>
					<path d="M318.936 109.16C312.672 109.16 306.988 107.768 301.884 104.984C296.858 102.123 292.875 98.2947 289.936 93.5C286.998 88.7053 285.528 83.3307 285.528 77.376C285.528 71.4213 286.92 66.0467 289.704 61.252C292.566 56.4573 296.432 52.668 301.304 49.884C306.176 47.0227 311.59 45.592 317.544 45.592C323.576 45.592 328.912 47.0613 333.552 50C338.192 52.8613 341.827 56.8053 344.456 61.832C347.163 66.8587 348.516 72.62 348.516 79.116V83.756H304.784C305.635 85.6893 306.756 87.3907 308.148 88.86C309.618 90.3293 311.358 91.4893 313.368 92.34C315.379 93.1133 317.544 93.5 319.864 93.5C322.416 93.5 324.736 93.1133 326.824 92.34C328.912 91.4893 330.691 90.3293 332.16 88.86L344.572 99.88C340.706 103.128 336.723 105.487 332.624 106.956C328.603 108.425 324.04 109.16 318.936 109.16ZM304.436 70.648H330.072C329.454 68.6373 328.487 66.936 327.172 65.544C325.935 64.0747 324.466 62.9533 322.764 62.18C321.063 61.3293 319.207 60.904 317.196 60.904C315.108 60.904 313.214 61.2907 311.512 62.064C309.811 62.8373 308.342 63.9587 307.104 65.428C305.944 66.82 305.055 68.56 304.436 70.648Z"/>
					<path d="M373.424 109.044C369.094 109.044 365.227 108.232 361.824 106.608C358.499 104.984 355.87 102.741 353.936 99.88C352.08 97.0187 351.152 93.732 351.152 90.02C351.152 84.1427 353.356 79.6187 357.764 76.448C362.25 73.2 368.475 71.576 376.44 71.576C381.235 71.576 385.875 72.156 390.36 73.316V70.3C390.36 67.2067 389.355 64.9253 387.344 63.456C385.411 61.9093 382.511 61.136 378.644 61.136C376.247 61.136 373.54 61.5227 370.524 62.296C367.586 63.0693 364.106 64.3067 360.084 66.008L353.356 52.204C358.383 49.9613 363.216 48.2987 367.856 47.216C372.574 46.056 377.291 45.476 382.008 45.476C390.515 45.476 397.127 47.5253 401.844 51.624C406.562 55.6453 408.92 61.3293 408.92 68.676V108H390.36V104.288C387.886 105.989 385.256 107.227 382.472 108C379.766 108.696 376.75 109.044 373.424 109.044ZM369.016 89.672C369.016 91.6053 369.867 93.1133 371.568 94.196C373.27 95.2787 375.628 95.82 378.644 95.82C380.887 95.82 382.975 95.588 384.908 95.124C386.919 94.66 388.736 93.9253 390.36 92.92V85.032C388.582 84.568 386.764 84.22 384.908 83.988C383.052 83.756 381.158 83.64 379.224 83.64C375.976 83.64 373.463 84.1813 371.684 85.264C369.906 86.2693 369.016 87.7387 369.016 89.672Z"/>
					<path d="M445.883 108.812C438.536 108.812 432.968 107.188 429.179 103.94C425.467 100.615 423.611 95.7427 423.611 89.324V62.296H411.199V46.636H423.611V30.86L442.403 26.8V46.636H459.571V62.296H442.403V85.612C442.403 88.3187 442.983 90.252 444.143 91.412C445.38 92.4947 447.507 93.036 450.523 93.036C452.069 93.036 453.461 92.9587 454.699 92.804C456.013 92.572 457.444 92.224 458.991 91.76V106.956C457.289 107.497 455.124 107.923 452.495 108.232C449.865 108.619 447.661 108.812 445.883 108.812Z"/>
					<path d="M463.427 108V26.8L482.219 23.9V50.928C487.014 47.5253 492.427 45.824 498.459 45.824C504.336 45.824 509.634 47.2547 514.351 50.116C519.068 52.9 522.819 56.6893 525.603 61.484C528.387 66.2013 529.779 71.4987 529.779 77.376C529.779 83.2533 528.348 88.5893 525.487 93.384C522.703 98.1013 518.875 101.852 514.003 104.636C509.208 107.42 503.834 108.812 497.879 108.812C495.018 108.812 492.234 108.464 489.527 107.768C486.82 106.995 484.307 105.912 481.987 104.52V108H463.427ZM495.211 92.92C498.304 92.92 501.05 92.2627 503.447 90.948C505.844 89.556 507.739 87.7 509.131 85.38C510.6 82.9827 511.335 80.3147 511.335 77.376C511.335 74.4373 510.6 71.808 509.131 69.488C507.739 67.0907 505.806 65.2347 503.331 63.92C500.934 62.528 498.227 61.832 495.211 61.832C492.582 61.832 490.184 62.2573 488.019 63.108C485.854 63.8813 483.92 65.08 482.219 66.704V88.164C483.843 89.6333 485.776 90.7933 488.019 91.644C490.262 92.4947 492.659 92.92 495.211 92.92Z"/>
					<path d="M565.674 109.16C559.41 109.16 553.764 107.768 548.738 104.984C543.711 102.123 539.728 98.2947 536.79 93.5C533.851 88.7053 532.382 83.3307 532.382 77.376C532.382 71.4213 533.851 66.0467 536.79 61.252C539.728 56.38 543.711 52.552 548.738 49.768C553.764 46.9067 559.41 45.476 565.674 45.476C571.938 45.476 577.583 46.9067 582.61 49.768C587.636 52.552 591.619 56.38 594.558 61.252C597.496 66.0467 598.966 71.4213 598.966 77.376C598.966 83.3307 597.496 88.7053 594.558 93.5C591.619 98.2947 587.636 102.123 582.61 104.984C577.583 107.768 571.938 109.16 565.674 109.16ZM565.674 92.92C568.535 92.92 571.087 92.224 573.33 90.832C575.572 89.44 577.351 87.584 578.666 85.264C579.98 82.8667 580.638 80.2373 580.638 77.376C580.638 74.4373 579.98 71.808 578.666 69.488C577.351 67.0907 575.534 65.196 573.214 63.804C570.971 62.412 568.458 61.716 565.674 61.716C562.89 61.716 560.338 62.412 558.018 63.804C555.775 65.196 553.996 67.0907 552.682 69.488C551.367 71.808 550.71 74.4373 550.71 77.376C550.71 80.2373 551.367 82.8667 552.682 85.264C553.996 87.584 555.775 89.44 558.018 90.832C560.338 92.224 562.89 92.92 565.674 92.92Z"/>
					<path d="M592.708 108L616.024 76.912L593.056 46.636H614.864L626.928 63.108L638.876 46.636H659.872L637.252 76.68L661.148 108H639.34L626.464 90.484L613.704 108H592.708Z"/>
					<path d="M163.346 42.485C159.824 38.0859 156.557 34.0674 156.557 34.0674L154.052 32.9204L143.763 32.2792L141.403 31.6712L140.092 29.6351C140.092 29.6351 138.692 25.4927 137.845 23.4784C130.991 7.03659 118.199 -0.931365 100.411 0.0865797C87.4458 0.827426 75.6405 5.37217 64.1792 11.0475C62.741 11.7603 60.7343 12.6225 59.374 13.3838C59.374 13.3838 62.5111 17.0216 62.778 17.3882L27.9789 30.9956L8.99506 44.0346L1.70268 59.8685L0 74.1799L0.46751 81.5795L4.96373 81.1069L6.66896 77.2326L6.33814 66.4774L5.31499 59.8082L8.34227 51.6689L8.98348 52.5107L8.90812 59.2818L14.6486 70.0816L21.4403 81.6299L11.3443 91.9725L2.42342 119.885H11.4515L16.7973 101.701C16.7973 101.701 22.779 95.9501 22.8467 95.9118C22.7918 96.189 23.0153 102.456 23.0153 102.456L35.2905 119.821L45.2051 119.882L32.2785 101.418L50.6735 75.0554L76.109 75.1474L87.8708 83.1601L88.5376 105.92L100.288 115.336L100.809 119.973H106.783L106.684 107.831L113.643 115.335L114.318 119.972L120.691 120C120.691 120 120.673 117.642 120.695 116.399C120.833 106.889 120.762 97.3809 120.762 87.8751C120.762 87.0588 120.921 85.7304 120.921 85.7304L122.282 83.0851C122.282 83.0851 124.29 81.7055 126.033 81.0605V99.5843C127.749 98.4794 137.635 90.823 137.635 90.823L143.252 87.903L150.373 86.2655L154.533 85.9947L157.548 83.7325L159.357 79.9237L166.072 61.4303L167.694 56.3095L167.242 49.3876C167.237 49.3914 165.206 44.8031 163.346 42.485ZM146.699 58.7352L143.832 58.7799L140.652 56.9852L138.676 51.7405L140.226 46.9366L144.227 39.2596L145.094 39.496L145.167 49.6022L148.42 51.7225L148.667 56.0258L146.699 58.7352Z"/>
				</svg>	
    	</Link>

    		<div>
				<ul className={'flex items-center justify-center hidden md:flex'}>
					<li>
						<Link className={'font-bold mx-4'} to={'/beef'}>Beef</Link>
					</li>

					<li>
						<Link className={'font-bold mx-4'} to={'/bison'}>Bison</Link>
					</li>

					<li>
						<Link className={'font-bold mx-4'} to={'/elk'}>Elk</Link>
					</li>

					<li>
						<Link className={'font-bold mx-4'} to={'/venison'}>Venison</Link>
					</li>

					<li>
						<Link className={'font-bold mx-4'} to={'/pork'}>Pork</Link>
					</li>

					<li>
						<Link className={'font-bold mx-4'} to={'/fish'}>Fish</Link>
					</li>

					<li>
						<Link className={'font-bold mx-4'} to={'/chicken'}>Chicken</Link>
					</li>

					<li>
						<Link className={'font-bold mx-4'} to={'/turkey'}>Turkey</Link>
					</li>
				</ul>
    		</div>
    	</nav>
    </header>
    </>
  )
}

export default Header
