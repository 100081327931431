import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"

const Service = ({ pageContext }) => {

  return (
    <Layout>
      <div className='max-w-xl mx-auto'>
      	<p>{pageContext.service.Name}</p>
      </div>
    </Layout>
  )
}

export default Service
