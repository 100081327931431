import React from "react"
import Header from "./header"
import "../styles/globals.css"

const Layout = ({ children }) => {


  return (
    <>  
        <Header/>
        <main className={'mt-28'}>{children}</main>
    </>
  )
}

export default Layout
